import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import './common.scss';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LeadsPage() {
  return (
    <>
      <Helmet>
        <title> Leads | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            All Leads
        </Typography>
            <div id='main-wrap'>
                <div className='table-wrap'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Lead Id</th>
                            <th>Lead Created by</th>
                            <th>Lead Created Date</th>
                            <th>Lead Status</th>
                            <th>Lead Qualification Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>L-23/24-101</td>
                            <td>User-0001</td>
                            <td>30-06-2023</td>
                            <td>
                                <span className='badge text-bg-danger'>Pending</span>
                            </td>
                            <td>
                                <span className='badge text-bg-success'>Active</span>
                            </td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li>
                                            <RouterLink to='/dashboard/edit-lead'><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>L-23/24-101</td>
                            <td>User-0001</td>
                            <td>30-06-2023</td>
                            <td>
                                <span className='badge text-bg-danger'>Pending</span>
                            </td>
                            <td>
                                <span className='badge text-bg-success'>Active</span>
                            </td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li>
                                            <RouterLink to='/dashboard/edit-lead'><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>L-23/24-101</td>
                            <td>User-0001</td>
                            <td>30-06-2023</td>
                            <td>
                                <span className='badge text-bg-danger'>Pending</span>
                            </td>
                            <td>
                                <span className='badge text-bg-success'>Active</span>
                            </td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li>
                                            <RouterLink to='/dashboard/edit-lead'><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>L-23/24-101</td>
                            <td>User-0001</td>
                            <td>30-06-2023</td>
                            <td>
                                <span className='badge text-bg-danger'>Pending</span>
                            </td>
                            <td>
                                <span className='badge text-bg-success'>Active</span>
                            </td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li>
                                            <RouterLink to='/dashboard/edit-lead'><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>L-23/24-101</td>
                            <td>User-0001</td>
                            <td>30-06-2023</td>
                            <td>
                                <span className='badge text-bg-danger'>Pending</span>
                            </td>
                            <td>
                                <span className='badge text-bg-success'>Active</span>
                            </td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li>
                                            <RouterLink to='/dashboard/edit-lead'><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>L-23/24-101</td>
                            <td>User-0001</td>
                            <td>30-06-2023</td>
                            <td>
                                <span className='badge text-bg-danger'>Pending</span>
                            </td>
                            <td>
                                <span className='badge text-bg-success'>Active</span>
                            </td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li>
                                            <RouterLink to='/dashboard/edit-lead'><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>L-23/24-101</td>
                            <td>User-0001</td>
                            <td>30-06-2023</td>
                            <td>
                                <span className='badge text-bg-danger'>Pending</span>
                            </td>
                            <td>
                                <span className='badge text-bg-success'>Active</span>
                            </td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li>
                                            <RouterLink to='/dashboard/edit-lead'><i className="bi bi-pen">&nbsp;</i> Edit</RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
      </Container>
    </>
  );
}