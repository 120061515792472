import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './common.scss';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LeadCreatePage() {
  return (
    <>
      <Helmet>
        <title> Create Lead | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Create Lead
        </Typography>
            <div id='main-wrap'>
              <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-basic-info-tab" data-bs-toggle="pill" data-bs-target="#pills-basic-info" type="button" role="tab" aria-controls="pills-basic-info" aria-selected="true">Basic Information</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-lead-check-tab" data-bs-toggle="pill" data-bs-target="#pills-lead-check" type="button" role="tab" aria-controls="pills-lead-check" aria-selected="false">Lead Checklist</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-communication-tab" data-bs-toggle="pill" data-bs-target="#pills-communication" type="button" role="tab" aria-controls="pills-communication" aria-selected="false">Communication</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-basic-info" role="tabpanel" aria-labelledby="pills-basic-info-tab" tabIndex="0">
                <h5 className='fw-bold mb-3'>Basic Information</h5>
                <div className='row'>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="name">
                              <span>Name</span>
                              <input type='text' id='name' className='form-control' placeholder=''/>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="email-id">
                              <span>Email ID</span>
                              <input type='email' id='email-id' className='form-control' placeholder=''/>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="phone">
                              <span>Phone Number</span>
                              <input type='tel' id='phone' className='form-control' placeholder=''/>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="address">
                              <span>Address</span>
                              <input type='text' id='address' className='form-control' placeholder=''/>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="aadhar">
                              <span>Aadhar Card Number</span>
                              <input type='tel' id='aadhar' className='form-control' placeholder=''/>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="pan">
                              <span>PAN Card Number</span>
                              <input type='text' id='pan' className='form-control' placeholder=''/>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-12'>
                      <div className='form-group'>
                          <label htmlFor="query">
                              <span>Lead Query</span>
                              <textarea id='query' className='form-control' placeholder='Please describe the nature of query entered by the lead'>Please describe the nature of query entered by the lead</textarea>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="source">
                              <span>Source of Lead</span>
                              <select id='source' className="form-select">
                                  <option selected>Select Source</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                              </select>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="user">
                              <span>Lead Assigned to</span>
                              <select id='user' className="form-select">
                                  <option selected>Select User</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                              </select>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-6'>
                      <div className='form-group'>
                          <label htmlFor="created-by">
                              <span>Lead Created by</span>
                              <select id='created-by' className="form-select">
                                  <option selected>Select User</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                              </select>
                          </label>
                      </div>
                  </div>
                  <div className='col-md-12'>
                      <div className='form-group mb-2'>
                          <button className='btn btn-primary'>Next</button>
                      </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade" id="pills-lead-check" role="tabpanel" aria-labelledby="pills-lead-check-tab" tabIndex="0">
                <div className='d-flex align-items-center justify-content-between'>
                  <h5 className='fw-bold mb-3'>Lead Checklist</h5>
                  <h6 className='fw-bold mb-3 bg-info text-white py-1 px-2 rounded'>1/2</h6>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                      <div className='form-group'>
                          <label htmlFor="question">
                              <span>Question Text</span>
                              <input type='text' id='question' className='form-control' placeholder='' value="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
tempor incididunt ut labore et dolore magna aliqua?" readOnly disabled />
                          </label>
                      </div>
                  </div>
                  <div className='col-md-12 questions'>
                    <span className='mb-2'>Answer Option</span>
                    <div className='form-group'>
                      <div className='row'>
                        <div className='col-1'>
                          <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                            <input type='checkbox' className='form-check-input mt-0 ms-0' />
                          </span>
                        </div>
                        <div className='col-11 ps-0'>
                            <label htmlFor="ans-op">
                                <input type='text' className='form-control' readOnly value='Option 1' />
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='row'>
                        <div className='col-1'>
                          <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                            <input type='checkbox' className='form-check-input mt-0 ms-0' />
                          </span>
                        </div>
                        <div className='col-11 ps-0'>
                            <label htmlFor="ans-op">
                                <input type='text' className='form-control' readOnly value='Option 2' />
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='row'>
                        <div className='col-1'>
                          <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                            <input type='checkbox' className='form-check-input mt-0 ms-0' />
                          </span>
                        </div>
                        <div className='col-11 ps-0'>
                            <label htmlFor="ans-op">
                                <input type='text' className='form-control' readOnly value='Option 3' />
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='row'>
                        <div className='col-1'>
                          <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                            <input type='checkbox' className='form-check-input mt-0 ms-0' />
                          </span>
                        </div>
                        <div className='col-11 ps-0'>
                            <label htmlFor="ans-op">
                                <input type='text' className='form-control' readOnly value='Option 4' />
                            </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 text-end question-btns'>
                    <button className='btn prev-btn' disabled>Prev Question</button>
                    <button className='btn next-btn'>Next Question</button>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group mt-5 mb-2'>
                        <button className='btn btn-primary'>Next</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="pills-communication" role="tabpanel" aria-labelledby="pills-communication-tab" tabIndex="0">
                <h5 className='fw-bold mb-3'>Communication</h5>
                <div className='row'>
                      <div className='col-md-6'>
                          <div className='form-group'>
                              <label htmlFor="interaction-type">
                                  <span>Type of Interaction</span>
                                  <select id='interaction-type' className="form-select">
                                      <option selected>Select Interaction Type</option>
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                  </select>
                              </label>
                          </div>
                      </div>
                      <div className='col-md-6'>
                          <div className='form-group'>
                              <label htmlFor="date-interaction">
                                  <span>Date of Interaction</span>
                                  <input type='date' className='form-control' />
                              </label>
                          </div>
                      </div>
                      <div className='col-md-12'>
                          <div className='form-group'>
                              <label htmlFor="inteaction-notes">
                                  <span>Inteaction Notes</span>
                                  <textarea id='inteaction-notes' className='form-control'>&nbsp;</textarea>
                              </label>
                          </div>
                      </div>                      
                      <div className='col-md-6'>
                          <div className='form-group'>
                              <label htmlFor="nit">
                                  <span>Next Interaction Type</span>
                                  <select id='nit' className="form-select">
                                      <option selected>Select Next Interaction Type</option>
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                  </select>
                              </label>
                          </div>
                      </div>
                      <div className='col-md-6'>
                          <div className='form-group'>
                              <label htmlFor="nid">
                                  <span>Next Interaction Date</span>
                                  <input type='date' id='nid' className='form-control' />
                              </label>
                          </div>
                      </div>
                      <div className='col-md-6'>
                          <div className='form-group'>
                              <label htmlFor="lead-status">
                                  <span>Lead Status</span>
                                  <select id='lead-status' className="form-select">
                                      <option selected>Select Lead Status</option>
                                      <option value="1">Pending</option>
                                      <option value="2">Hold</option>
                                      <option value="3">Active</option>
                                  </select>
                              </label>
                          </div>
                      </div>
                      <div className='col-md-12'>
                          <div className='form-group mb-2'>
                              <button className='btn btn-primary'>Submit</button>
                          </div>
                      </div>
                </div>
              </div>
            </div>
            </div>
      </Container>
    </>
  );
}