import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import './common.scss';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ReportingEnginePage() {
  return (
    <>
      <Helmet>
        <title> Reporting Engine | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Reporting Engine
        </Typography>
            <h5 className='mb-3 fw-bold'>Widgets</h5>
            <div className='row mb-4 reporting-engine'>
                <div className='col-md-4 mb-4'>
                    <div className='card d-flex flex-row'>
                        <div>
                            <h5>Widget 1</h5>
                            <span>12345</span>
                        </div>
                        <i className="bi bi-bar-chart">&nbsp;</i>
                    </div>
                </div>
                <div className='col-md-4 mb-4'>
                    <div className='card d-flex flex-row'>
                        <div>
                            <h5>Widget 2</h5>
                            <span>234234</span>
                        </div>
                        <i className="bi bi-graph-up-arrow">&nbsp;</i>
                    </div>
                </div>
                <div className='col-md-4 mb-4'>
                    <div className='card d-flex flex-row'>
                        <div>
                            <h5>Widget 1</h5>
                            <span>12345</span>
                        </div>
                        <i className="bi bi-pie-chart">&nbsp;</i>
                    </div>
                </div>
            </div>


            
            <h5 className='mb-3 fw-bold'>Reports</h5>
            <div className='row reports'>
                <div className='col-md-4 mb-4'>
                    <div className='card d-flex flex-row'>
                        <i className="bi bi-file-earmark-bar-graph">&nbsp;</i>
                        <h5>Leads per source</h5>
                    </div>
                </div>
                <div className='col-md-4 mb-4'>
                    <div className='card d-flex flex-row'>
                        <i className="bi bi-file-earmark-bar-graph">&nbsp;</i>
                        <h5>Leads per status per source</h5>
                    </div>
                </div>
                <div className='col-md-4 mb-4'>
                    <div className='card d-flex flex-row'>
                        <i className="bi bi-file-earmark-bar-graph">&nbsp;</i>
                        <h5>Leads assigned to users and overall</h5>
                    </div>
                </div>
                <div className='col-md-4 mb-4'>
                    <div className='card d-flex flex-row'>
                        <i className="bi bi-file-earmark-bar-graph">&nbsp;</i>
                        <h5>Leads per campaign</h5>
                    </div>
                </div>
            </div>
      </Container>
    </>
  );
}