import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './common.scss';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UserCreatePage() {
  return (
    <>
      <Helmet>
        <title> Create User | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Create User
        </Typography>
            <div id='main-wrap'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="user-type">
                                <span>User Type</span>
                                <select id='user-type' className="form-select">
                                    <option selected>Select User Type</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="department">
                                <span>Department</span>
                                <select id='Department' className="form-select">
                                    <option selected>Select Department</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="name">
                                <span>Name</span>
                                <input type='text' id='name' className='form-control' placeholder=''/>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="email-id">
                                <span>Email ID</span>
                                <input type='email' id='email-id' className='form-control' placeholder=''/>
                            </label>
                        </div>
                    </div>
                    
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="user-role">
                                <span>User Role</span>
                                <select id='user-role' className="form-select">
                                    <option selected>Select User Role</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="reporting-user">
                                <span>Reporting User Name</span>
                                <select id='reporting-user' className="form-select">
                                    <option selected>Select Reporting User Name</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <div className="form-check form-switch">
                                <label className="form-check-label" htmlFor="external-user">
                                    <input className="form-check-input" type="checkbox" role="switch" id="external-user" />
                                    <span>Is External User</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='form-group mb-2'>
                            <button className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
      </Container>
    </>
  );
}