import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import UserCreatePage from './pages/UserCreatePage';
import LeadCreatePage from './pages/LeadCreatePage';
import LeadsPage from './pages/LeadsPage';
import CreateCampaignPage from './pages/CreateCampaignPage';
import CampaignPage from './pages/CampaignPage';
import LQCPage from './pages/LQCPage';
import ReportingEnginePage from './pages/ReportingEnginePage';
import EditLeadPage from './pages/EditLeadPage';
import UserRolePage from './pages/UserRolePage';
import DepartmentPage from './pages/DepartmentPage';
import DepartmentCreatePage from './pages/DepartmentCreatePage';
import UserRoleCreatePage from './pages/UserRoleCreatePage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'create-user', element: <UserCreatePage /> },
        { path: 'create-lead', element: <LeadCreatePage /> },
        { path: 'leads', element: <LeadsPage /> },
        { path: 'edit-lead', element: <EditLeadPage /> },
        { path: 'create-campaign', element: <CreateCampaignPage /> },
        { path: 'campaigns', element: <CampaignPage /> },
        { path: 'lead-qualification-checklist', element: <LQCPage /> },
        { path: 'reporting-engine', element: <ReportingEnginePage /> },
        { path: 'user-role', element: <UserRolePage /> },
        { path: 'department', element: <DepartmentPage /> },
        { path: 'create-department', element: <DepartmentCreatePage /> },
        { path: 'create-user-role', element: <UserRoleCreatePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
