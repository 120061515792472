import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import './common.scss';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function DepartmentPage() {
  return (
    <>
      <Helmet>
        <title> Departments | Ckers </title>
      </Helmet>

      <Container>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <Typography variant="h4" sx={{ mb: 0 }}>
                    Departments
                </Typography>
                <RouterLink to='/dashboard/create-department' className='add-btn btn-primary btn'><i className="bi bi-plus">&nbsp;</i> Add Department</RouterLink>
            </div>
            <div id='main-wrap'>
                <div className='table-wrap'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Department ID</th>
                            <th>Department Name</th>
                            <th>Department Status</th>
                            <th>Department Created by</th>
                            <th>Department Created On</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-success text-white'>Active</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-danger text-white'>Pending</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-warning text-white'>Hold</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-success text-white'>Active</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-success text-white'>Active</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-success text-white'>Active</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-danger text-white'>Pending</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>DPT-00012</td>
                            <td>Xyz</td>
                            <td><span className='badge text-bg-success text-white'>Active</span></td>
                            <td>
                                X user
                            </td>
                            <td>03-07-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
      </Container>
    </>
  );
}