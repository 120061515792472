import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './common.scss';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LQCPage() {
  return (
    <>
      <Helmet>
        <title>Lead Qualification Checklist | Ckers </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            Lead Qualification Checklist
        </Typography>
            <div id='main-wrap'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor="checklist-name">
                                <span>Checklist Name</span>
                                <input type='text' id='checklist-name' className='form-control' placeholder=''/>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor="question-text">
                                <span>Question Text</span>
                                <input type='text' id='question-text' className='form-control' placeholder=''/>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label htmlFor="question-type">
                                <span>Question Type</span>
                                <select id='question-type' className="form-select">
                                    <option selected>Select Question Type</option>
                                    <option value="1">Input</option>
                                    <option value="2">Checkbox</option>
                                    <option value="3">Textarea</option>
                                    <option value="3">Radio Button</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        &nbsp;
                    </div>
                    
                    <div className='col-md-9'>
                        <div className='form-group mb-0'>
                            <label htmlFor="user-role">
                                <span>Answer Options</span>
                                <input type='hidden' className='d-none' />
                            </label>
                        </div>
                    </div>
                    
                    <div className='col-md-3'>
                        <div className='form-group mb-0'>
                            <label htmlFor="user-role">
                                <span>Score</span>
                                <input type='hidden' className='d-none' />
                            </label>
                        </div>
                    </div>

                    {/* checkbox */}
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='checkbox' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='checkbox' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='checkbox' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='checkbox' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    {/* radio button */}
                    <div className='col-12 d-none'>
                        <div className='row'>
                            <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='radio' name='radio' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='radio' name='radio' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' name='radio' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='radio' name='radio' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12'>
                            <div className='form-group row'>
                                <div className='col-1'>
                                    <span className='bg-light d-flex h-100 align-items-center justify-content-center rounded'>
                                        <input type='radio' name='radio' className='form-check-input mt-0 ms-0' />
                                    </span>
                                </div>
                                <div className='col-8 ps-0'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <label htmlFor="ans-op">
                                        <input type='text' className='form-control' />
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className='col-md-12'>
                        <div className='form-group text-end'>
                            <button className='btn btn-info add-more'>Add more</button>
                        </div>
                    </div>
                    
                    <div className='col-md-12'>
                        <div className='form-group mb-2'>
                            <button className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
      </Container>
    </>
  );
}