// component
import SvgColor from '../../../components/svg-color';

import "bootstrap-icons/font/bootstrap-icons.css";
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <i className="nav-icon bi bi-box">&nbsp;</i>,
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: <i className="nav-icon bi bi-people">&nbsp;</i>,
  },
  {
    title: 'Create User',
    path: '/dashboard/create-user',
    icon: <i className="nav-icon bi bi-person-plus">&nbsp;</i>,
  },
  {
    title: 'Leads',
    path: '/dashboard/leads',
    icon: <i className="nav-icon bi bi-collection">&nbsp;</i>,
  },
  {
    title: 'Lead Qualification Checklist',
    path: '/dashboard/lead-qualification-checklist',
    icon: <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>,
  },
  {
    title: 'Create Lead',
    path: '/dashboard/create-lead',
    icon: <i className="nav-icon bi bi-folder-plus">&nbsp;</i>,
  },
  {
    title: 'Create Campaign',
    path: '/dashboard/create-campaign',
    icon: <i className="nav-icon bi bi-node-plus">&nbsp;</i>,
  },
  {
    title: 'Campaigns',
    path: '/dashboard/campaigns',
    icon: <i className="nav-icon bi bi-megaphone">&nbsp;</i>,
  },
  {
    title: 'Reporting Engine',
    path: '/dashboard/reporting-engine',
    icon: <i className="nav-icon bi bi-file-earmark-bar-graph">&nbsp;</i>,
  },
];

export default navConfig;
