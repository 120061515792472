import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import './common.scss';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UserRolePage() {
  return (
    <>
      <Helmet>
        <title> User Roles | Ckers </title>
      </Helmet>

      <Container>
        <div className="d-flex align-items-center justify-content-between mb-4">
            <Typography variant="h4" sx={{ mb: 0 }}>
                User Roles
            </Typography>
            <RouterLink to='/dashboard/create-user-role' className='add-btn btn-primary btn'><i className="bi bi-plus">&nbsp;</i> Add User Role</RouterLink>
        </div>
            <div id='main-wrap'>
                <div className='table-wrap'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>RoleID</th>
                            <th>Role Name</th>
                            <th>Role Status</th>
                            <th>Role Created By</th>
                            <th>Role Created On</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>RID-00121</td>
                            <td>Role - Xyz</td>
                            <td>User Type</td>
                            <td>
                                <span className='badge text-bg-success text-white'>Active</span>
                            </td>
                            <td>01-08-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>RID-00121</td>
                            <td>Role - Xyz</td>
                            <td>User Type</td>
                            <td>
                                <span className='badge text-bg-success text-white'>Active</span>
                            </td>
                            <td>01-08-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>RID-00121</td>
                            <td>Role - Xyz</td>
                            <td>User Type</td>
                            <td>
                                <span className='badge text-bg-danger text-white'>Pending</span>
                            </td>
                            <td>01-08-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>RID-00121</td>
                            <td>Role - Xyz</td>
                            <td>User Type</td>
                            <td>
                                <span className='badge text-bg-success text-white'>Active</span>
                            </td>
                            <td>01-08-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>RID-00121</td>
                            <td>Role - Xyz</td>
                            <td>User Type</td>
                            <td>
                                <span className='badge text-bg-success text-white'>Active</span>
                            </td>
                            <td>01-08-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>RID-00121</td>
                            <td>Role - Xyz</td>
                            <td>User Type</td>
                            <td>
                                <span className='badge text-bg-danger text-white'>Pending</span>
                            </td>
                            <td>01-08-2023</td>
                            <td>
                                <div className="btn-group dropstart">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-three-dots-vertical">&nbsp;</i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><i className="bi bi-eye">&nbsp;</i> View</li>
                                        <li><i className="bi bi-pen">&nbsp;</i> Edit</li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
      </Container>
    </>
  );
}