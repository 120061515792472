import PropTypes from 'prop-types';
import { NavLink, NavLink as RouterLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import $ from 'jquery';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
// ----------------------------------------------------------------------







NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {

  useEffect(() => {

    $(".no-dropdown").click(function() {  
      $(this).addClass("");  
      $('.dropdown-toggle').addClass("collapsed");  
      $('.nav-collapse').removeClass("show");  
    });

    // $('.dropdown').click(function () {
    //   $('.dropdown').removeClass('active');
    //   $(this).toggleClass('active');
    // });

    // $('.dropdown').each(function(index) {
    //   const uniqueIdentifier = new Date().getTime() + index;
    //   const uniqueClass = 'active' + uniqueIdentifier;
    //   $(this).addClass(uniqueClass);
    // });

    // const currentUrl = window.location.href;
    // const element = $(".dropdown");
    // const classToAdd = "active";
    // if (currentUrl.match(element.attr("href"))) {
    //   element.addClass(classToAdd);
    // }
  }, []);

  return (
    <Box {...other}>

      <nav className="nav">
        <div>
          <div id="mainNav">
            <div className="nav_list no-dropdown"> 
              <RouterLink to="/dashboard/app" className="nav_link"> 
                <i className="nav-icon bi bi-box">&nbsp;</i>
                <span className="nav_name">Dashboard</span>
              </RouterLink> 
            </div>

            {/* Collapse */}
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#usersMenu" role="button" data-bs-toggle="collapse" data-bs-target="#usersMenu" aria-expanded="false" aria-controls="usersMenu">
                <i className="bi-person nav-icon">&nbsp;</i>
                <span className="nav_link-title">Users</span>
              </a>

              <div id="usersMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list"> 
                  <RouterLink to="/dashboard/create-user" className="nav_link"> 
                    <i className="nav-icon bi bi-person-plus">&nbsp;</i>
                    <span className="nav_name">Create User</span>
                  </RouterLink>
                </div>
                <div className="nav_list"> 
                  <RouterLink to="/dashboard/user" className="nav_link"> 
                    <i className="nav-icon bi bi-people">&nbsp;</i>
                    <span className="nav_name">Users</span>
                  </RouterLink> 
                </div>
              </div>
            </div>
            {/* Collapse End */}



            {/* Collapse */}
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#leadsMenu" role="button" data-bs-toggle="collapse" data-bs-target="#leadsMenu" aria-expanded="false" aria-controls="leadsMenu">
              <i className="nav-icon bi bi-collection">&nbsp;</i>
                <span className="nav_link-title">Leads</span>
              </a>

              <div id="leadsMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list"> 
                    <RouterLink to="/dashboard/create-lead" className="nav_link"> 
                      <i className="nav-icon bi bi-folder-plus">&nbsp;</i>
                      <span className="nav_name">Create Lead</span>
                    </RouterLink>
                  </div>
                  <div className="nav_list"> 
                    <RouterLink to="/dashboard/leads" className="nav_link"> 
                      <i className="nav-icon bi bi-collection">&nbsp;</i>
                      <span className="nav_name">Leads</span>
                    </RouterLink> 
                  </div>
              </div>
            </div>
            {/* Collapse End */}


            {/* Collapse */}
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#campaignMenu" role="button" data-bs-toggle="collapse" data-bs-target="#campaignMenu" aria-expanded="false" aria-controls="campaignMenu">
              <i className="nav-icon bi bi-megaphone">&nbsp;</i>
                <span className="nav_link-title">Campaigns</span>
              </a>

              <div id="campaignMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list"> 
                    <RouterLink to="/dashboard/create-campaign" className="nav_link"> 
                      <i className="nav-icon bi bi-node-plus">&nbsp;</i>
                      <span className="nav_name">Create Campaign</span>
                    </RouterLink>
                  </div>
                  <div className="nav_list"> 
                    <RouterLink to="/dashboard/campaigns" className="nav_link"> 
                      <i className="nav-icon bi bi-megaphone">&nbsp;</i>
                      <span className="nav_name">Campaign</span>
                    </RouterLink> 
                  </div>
              </div>
            </div>
            {/* Collapse End */}

            <div className="nav_list no-dropdown"> 
              <RouterLink to="/dashboard/reporting-engine" className="nav_link"> 
                <i className="nav-icon bi bi-file-earmark-bar-graph">&nbsp;</i>
                <span className="nav_name">Reporting Engine</span>
              </RouterLink> 
            </div>

              {/* Collapse */}
              <div className="nav_list">
                <a className="nav_link dropdown-toggle collapsed" href="#masterMenu" role="button" data-bs-toggle="collapse" data-bs-target="#masterMenu" aria-expanded="false" aria-controls="masterMenu">
                  <i className="nav-icon bi bi-app">&nbsp;</i>
                  <span className="nav_link-title">Master</span>
                </a>

                <div id="masterMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                  <div className="nav_list"> 
                    <RouterLink to="/dashboard/lead-qualification-checklist" className="nav_link"> 
                      <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>
                      <span className="nav_name">Lead Qualification Checklist</span>
                    </RouterLink>
                  </div>
                  <div className="nav_list"> 
                      <RouterLink to="/dashboard/department" className="nav_link"> 
                        <i className="nav-icon bi bi-building">&nbsp;</i>
                        <span className="nav_name">Department</span>
                      </RouterLink>
                  </div>
                  <div className="nav_list"> 
                      <RouterLink to="/dashboard/user-role" className="nav_link"> 
                        <i className="nav-icon bi bi-person-vcard">&nbsp;</i>
                        <span className="nav_name">User Role</span>
                      </RouterLink>
                  </div>
                </div>
              </div>
              {/* Collapse End */}
          </div>
        </div>
      </nav>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
